import { Container, Spinner } from '@chakra-ui/react';
import axios, { isAxiosError } from 'axios';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { defaultSpinnerProps } from 'components/AsyncWrapper';
import useUserContext from 'hooks/useUserContext';

export default function ProcessUsageBasedSubscription(): JSX.Element {
  const { refreshUser } = useUserContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const sessionId = searchParams.get('sessionId');
  const navigate = useNavigate();
  const isCheckoutSessionProcessed = useRef<boolean>(false);

  useEffect(() => {
    if (!isCheckoutSessionProcessed.current && sessionId) {
      const createSubscription = async (): Promise<void> => {
        try {
          await axios.post('/api/billing/process-setup-session', { sessionId });
          await refreshUser();
          isCheckoutSessionProcessed.current = true;
          navigate('/settings/billing');
        } catch (error) {
          throw new Error(isAxiosError(error) ? error.response!.data.message : error);
        }
      };
      void createSubscription();
    }
  }, [sessionId, navigate, refreshUser, setSearchParams]);

  return (
    <Container centerContent>
      <Spinner
        {...defaultSpinnerProps}
      />
    </Container>
  );
}
