import { Else, If, Then } from 'react-if';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useLogger } from 'react-use';

import PrivateRoute from './components/PrivateRoute';
import ErrorPage from 'components/error/ErrorPage';
import useUserContext from 'hooks/useUserContext';
import DevPage from 'pages/dev/DevPage';
import ImpersonationPage from 'pages/ImpersonationPage';
import LoginPage from 'pages/login-page/LoginPage';
import ProcessUsageBasedSubscription from 'pages/ProcessUsageBasedSubscription';
import RequestResetPassword from 'pages/RequestResetPassword';
import ResetPassword from 'pages/ResetPassword';
import SettingsPage from 'pages/settings-page/SettingsPage';
import Studylist from 'pages/studies/Studylist';

export default function RouteIndex(): JSX.Element {
  const { isLoggedIn } = useUserContext();
  useLogger('RouteIndex');

  return (
    <Routes>
      <Route path='*' element={<ErrorPage title='Page not found' />} />
      <Route
        path='/'
        element={(
          <If condition={!isLoggedIn}>
            <Then>
              <Navigate to='/login' />
            </Then>
            <Else>
              <Navigate to='/studies' />
            </Else>
          </If>
        )}
      />
      <Route path='/login' element={<LoginPage />} />
      <Route
        path='/request-reset-password'
        element={<RequestResetPassword />}
      />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path='/impersonate' element={<ImpersonationPage />} />
      <Route element={<PrivateRoute />}>
        <Route path='/dev' element={<DevPage />} />
        <Route path='/settings' element={<Navigate to='/settings/my-profile' />} />
        <Route path='/settings/:tabName' element={<SettingsPage />} />
        <Route path='/studies' element={<Studylist />} />
        <Route path='/studylist' element={<Navigate to='/studies' />} />
        <Route path='/process-usage-based-billing-setup' element={<ProcessUsageBasedSubscription />} />
      </Route>
    </Routes>
  );
}
